<template>
	<Header
		:userData="userData"
		v-if="!isForbidden"
	/>
	<Menu
		v-if="!isForbidden"
	/>
	<Preloader v-if=" preloader.main > 0"/>
	<router-view
		v-slot="{ Component, route }"
	>
		<transition name="fade">
			<component
				:is="Component "
				:key="route.path"
			/>
		</transition>
	</router-view>
	<Footer @click="showModal('DashboardSupport')"/>
	<ScrollTop
		v-if="showScrollTop"
	/>
	<Modal/>
</template>

<script>
import Header from "@/components/template/Header";
import Menu from "@/components/template/Menu";
import Preloader from '@/components/ui/Preloader';
import Footer from "@/components/template/Footer";
import ScrollTop from "@/components/template/ScrollTop";
import Modal from "@/components/template/Modal";

export default {
	name: 'App',
	components: {
		Preloader,
		Menu,
		Header,
		Footer,
		ScrollTop,
		Modal
	},
	data() {
		return {
			showScrollTop: false,
		}
	},
	watch: {
		forbiddenState: 'redirectIfNeeded'
	},
	mounted() {
		this.load();
	},
	destroyed() {
		window.removeEventListener("scroll", this.handleScroll);
	},
	computed: {
		userData() {
			return this.$store.getters.GET_USER;
		},
		preloader() {
			return this.$store.getters.PRELOADER;
		},
		isForbidden() {
			return this.$route.path == '/forbidden';
		},
		forbiddenState() {
			return this.$store.getters.GET_FORBIDDEN_STATE;
		},
	},
	methods: {
		load() {
			window.addEventListener("scroll", this.handleScroll);
			if (!localStorage.user && !this.$route.query.hash) {
				this.$store.dispatch('fetchUser', 1)
			}
			if (localStorage.user && !this.$route.query.hash && !this.forbiddenState) {
				let dataUser = JSON.parse(localStorage.user)
				this.$store.dispatch('setUser', dataUser)
				this.$store.dispatch('getMain', dataUser.user.role)
			}
			if (this.$route.query.hash) {
				this.$store.dispatch('fetchUser', this.$route.query.hash)
				this.$router.replace({query: {}});

				if (localStorage.user) {
					let userData = JSON.parse(localStorage.user);

					if (userData.user && userData.user.role) {
						let firstMenu = userData.menu[0];

						this.$router.replace({name: firstMenu.path, params: {formId: firstMenu.formId}});
					}
				}
			}
		},
		handleScroll() {
			this.showScrollTop = window.pageYOffset >= 50;
		},
		loadData() {
			if (this.$route.params) {
				if (this.$route.params.formId) {
					this.$store.dispatch('getForm', this.$route.params.formId)
				}
			}
		},
		redirectIfNeeded() {
			if (this.forbiddenState) {
				this.$router.replace({name: 'forbidden'})
			}
		},
		showModal(modalCode) {
			let data = {
				code: modalCode
			}
			this.$store.commit('SET_MODAL_DATA', data)
		},
	}
}
</script>

<style lang="scss">
@import "./assets/styles/global.scss";

#app {
	font-family: $font, "sans-serif";
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	position: relative;


	&__content {
		padding-top: 50px;
		max-width: $max-content-xl;
		width: 100%;
		margin: 0 auto;
	}

	&__content-title {
		margin-bottom: 30px;
	}

	&__content-nav {
		display: flex;
		align-items: center;
		z-index: 3;
		position: sticky;
		top: 160px;
		height: 50px;
		transition: $transition-main;
	}

	&__content-nav_sticky {
		position: fixed;
		top: 60px;
	}

	&__footer {
		margin-top: auto;
		margin-bottom: 58px;
	}

	&__scrolltop {
		transition: $transition-main;
		max-width: $max-content-xl;
		width: fit-content;
		display: flex;
		position: fixed;
		right: 10%;
		bottom: 58px;

		.button {
			margin-right: 0;
			margin-left: auto;
			width: 40px;
		}
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: all 0.6s linear;
	opacity: 0;
	filter: blur(20px);
}


</style>
